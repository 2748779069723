<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
#app {
  font-family: 'Source Code Pro', monospace;
  background-color: #4d4d4d;
}
html {
  background-color: #4d4d4d;
}
</style>
