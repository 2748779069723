<template>
  <NavBar/>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";

export default {
  name: "Home",
  title: "Cameron Wragg - Home",
  components: {
    NavBar
  }
};
</script>